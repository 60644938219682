(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('CursoDialogController', CursoDialogController);

    CursoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Curso', 'Upload', '$sce'];

    function CursoDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Curso, Upload, $sce) {
        var vm = this;

        vm.curso = entity;
        vm.clear = clear;
        vm.save = save;
        vm.trustedHtml = trustedHtml;
        vm.carregando = false;
        vm.fileOriginal = null;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.tinymceOptions = {
            selector: 'textarea#local-upload',
            plugins: 'link image code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | image code',
            images_upload_handler: function (blobInfo, success, failure) {
                console.log(blobInfo);
                upload(blobInfo.blob(), success);
            }
        };


        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function upload(blobInfo, callback) {
            console.log(" iniciou uma chamada ", blobInfo);
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: blobInfo,
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                callback(resp.data.conteudoFormatado);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save(){
            if (vm.fileOriginal) {
                upload(vm.fileOriginal, persist);
                console.log("logo do maluco", vm.empregador.logo);
            } else {
                persist();
            }
        }

        function persist(imagem) {
            if (imagem) {
                vm.curso.imagem = imagem;
            }
            vm.isSaving = true;
            if (vm.curso.id !== null) {
                Curso.update(vm.curso, onSaveSuccess, onSaveError);
            } else {
                Curso.save(vm.curso, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:cursoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
