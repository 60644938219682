(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ProdutoDialogController', ProdutoDialogController);

    ProdutoDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Produto', 'ConfirmService', '$sce', 'Principal', '$uibModal'];

    function ProdutoDialogController($timeout, $scope, $state, $stateParams, entity, Produto, ConfirmService, $sce, Principal, $uibModal) {
        var vm = this;

        vm.produto = entity;
        vm.clear = clear;
        vm.comprar = comprar;
        vm.trustedHtml = trustedHtml;

        vm.isAuthenticated = Principal.isAuthenticated;

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function clear() {
            $state.go('produto');
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:produtoUpdate', result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('avaliacao');
            ConfirmService.swal({
                title: "Atenção!",
                text: "A maioria das produtos requerem avaliações, verifique e inicie as etapas disponíveis",
                showConfirmButton: true
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function comprar() {
            if (vm.isAuthenticated()) {
                //gerar uma venda.. salvar no banco o sucesso disso deve ser redirecionado para a pagina de checkout
                console.log("id: ", vm.produto.id);
                $state.go("carrinho-wizard", {"id": vm.produto.id});
                //$state.go("carrinho-wizard");
                //$state.href("carrinho", {"id": produto.id});
                // $uibModalInstance.close();
            } else {


                ConfirmService.swal({
                        title: "Você já tem um Perfil MySkills?",
                        text: "Para adiquirir uma avaliação é necessário um Perfil MySkills!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Cadastrar AGORA",
                        cancelButtonText: "Já tenho cadastro",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            abrirRegistro();
                            ConfirmService.close();
                        } else {
                            abrirLogin();
                            ConfirmService.close();
                        }
                    });
            }


        }

        function abrirLogin() {
            $uibModal.open({
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    previousState: function () {
                        return {};
                    }, pagingParams: function () {
                        return {};
                    },
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function () {
                $state.go("carrinho-wizard", {"id": vm.produto.id});
            }, function () {

            });
        }

        function abrirRegistro() {
            $uibModal.open({
                templateUrl: 'app/account/register/register.html',
                controller: 'RegisterController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    pagingParams: function () {
                        return {};
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                $state.go("carrinho-wizard", {"id": vm.produto.id});
            }, function () {

            });
        }

    }
})();
